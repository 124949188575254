  .cont-bn {
      height: 26.25rem;
      color: #FFFFFF;
      background: rgb(35, 12, 149);
      background: linear-gradient(90deg, rgba(35, 12, 149, 1) 0%, rgba(27, 82, 241, 1) 100%);
  }

  .cont-bn .banner {
      width: 90%;
  }

  .banner .fl-box {
      margin-top: 8rem;
      width: 70%;
  }

  .banner .fr-box {
      margin-top: 11rem;
      width: 10%;
  }

  .btn-bn {
      height: 4rem;
      width: 12.25rem;
      border-radius: .6rem;
      background-color: #ffffff1c;
  }

  .bn-btn {
      color: #FFFFFF;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.5rem;
  }

  .cont-fo {
      padding-top: 4rem;
      min-height: 34rem;
      color: #fff;
  }

  .imgbase {
      vertical-align: middle;
  }

  .Footer-Box {
      margin-bottom: 3rem;
  }

  .Footer-Box .fl-box {
      width: 20%;
      text-align: center;
  }

  .Footer-Box .fl-box img {
      width: 40%;
  }

  .Footer-Box .fc-box {
      width: 30%;
  }

  .Footer-Box .fr-box {
      width: 16%;
  }

  @media (min-width:550px) and (max-width:896px) {
    .banner .fl-box {
        height: 0rem;
        margin-left: 0rem;
        margin-top: 8rem;
        width: 60%;
    }
  
    .banner .fr-box {
        height: 0rem;
        margin-right: 0rem;
        width: 30%;
    }
  }

  @media (max-width:550px) {
    .banner .fl-box {
        height: 0rem;
        margin-left: 0rem;
        margin-top: 8rem;
        width: 100%;
    }
  
    .banner .fr-box {
        height: 0rem;
        margin-right: 0rem;
        margin-top: 8rem;
        width: 100%;
    }
    .Footer-Box .fl-box img {
        height: 6rem;
        width: 6rem;
    }
  
    .Footer-Box .fc-box {
        width: 90%;
    }
  
    .Footer-Box .fr-box {
        margin-top: 2rem;
        margin-right: 0rem;
        width: 90%;
    }
    .footer .fl {
        float: none;
        text-align: center;
    }
    .footer .fr {
        float: none;
        text-align: center;
    }
    .py-1 {
        padding: 1rem 0rem;
    }
  }
  
  @media (max-width:320px) {
    .banner .fl-box {
        height: 0rem;
        margin-left: 0rem;
        margin-top: 5rem;
        width: 100%;
    }
  
    .banner .fr-box {
        height: 0rem;
        margin-right: 1rem;
        margin-top: 11rem;
        width: 100%;
    }
}