.cont1 {
  min-height: 50rem;
}

.box1 {
  padding-top: 14rem;
  width: calc(100% - 20%);
  margin: auto;
  text-align: center;
}

.box2 {
  padding-top: 2.813rem;
  width: calc(100% - 39%);
  margin: auto;
  text-align: center;
}

.btn-start {
  margin-bottom: 5rem;
  height: 4rem;
  width: 16.375rem;
  border-radius: .6rem;
  background-color: #144DE0;
  box-shadow: 0 .4rem 1rem 0 rgba(0, 0, 0, 0.48);
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.btn-start img {
  transform: translateX(.4rem);
  transition: all 0.3s ease;
}

.btn-start:hover img {
  transform: translateX(1rem);
}

.img-arrow {
  vertical-align: bottom;
}

.btn-bn img {
  transform: translateX(.4rem);
  transition: all 0.3s ease;
}

.btn-bn:hover img {
  transform: translateX(1rem);
}

.cont2 {
  text-align: center;
  padding-top: 4.5rem;
  min-height: 20rem;
  background-color: #040812;
  margin-bottom: 10rem;
}

.cont2T {
  margin: auto;
  color: #6E7681;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.cont2 .logo-jupiter {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  height: 3.524rem;
  width: 6.502rem;
}

.cont2 .logo-bestinvest {
  margin-right: 2.5rem;
  height: 2.625rem;
  width: 7.875rem;
}

.cont2 .logo-rdt {
  margin-right: 1.172rem;
  height: 2.75rem;
  width: 6.233rem;
}

.cont2 .logo-vector {
  margin-right: 2.5rem;
  height: 2.75rem;
  width: 11.944rem;
}

.cont2 .logo-checkout {
  margin-top: 1rem;
  height: 1.375rem;
  width: 10.375rem;
}

.cont2 .logo-ifds {
  height: 2.098rem;
  width: 11.25rem;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.cont2 .logo-investec {
  height: 2.123rem;
  width: 11.25rem;
  margin-right: 2.5rem;
}

.cont2 .logo-optum {
  height: 2.75rem;
  width: 9.169rem;
}

.cont3 {
  height: 20rem;
}

.img-border {
  box-sizing: border-box;
  height: 5rem;
  width: 5rem;
  padding: .6rem;
  border: 0.006rem solid #404D69;
  border-radius: 0.625rem;
  background-color: #ffffff12;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.55);
}

.cont4 {
  height: 72rem;
}

.cont4-box1 {
  margin: auto;
  width: 30%;
  text-align: center;
}

.cont4-box2 {
  margin: auto;
  width: 42%;
  text-align: center;
}

.cont4 .v-img {
  margin-left: 6rem;
  margin-top: 14rem;
}

.cont4 .v-line {
  position: relative;
}

.cont4 .v-img2 {
  display: none;
}

.cont4 .map {
  position: absolute;
  top: 10rem;
  left: 15rem;
}

.cont4 .btn-map {
  box-sizing: border-box;
  height: 2.25rem;
  width: 8.75rem;
  border: .1rem solid #257CFB;
  border-radius: 4rem;
  background-color: #257CFB;
}

.cont4 .map-btn {
  pointer-events: none;
  color: #FFFFFF;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.313rem;
}

.cont4 .text-map {
  position: absolute;
  top: 9rem;
  left: 13rem;
}

.cont4 .cloud {
  position: absolute;
  top: 13rem;
  left: 4rem;
}

.cont4 .logo {
  position: absolute;
  top: 14rem;
  left: 59.7rem;
  height: 5rem;
  width: 5rem;
}

.cont4 .text-logo {
  position: absolute;
  top: 16rem;
  left: 65rem;
  width: 20%;
}

.cont4 .unionLeft {
  position: absolute;
  top: 17.8rem;
  left: 57.3rem;
}

.cont4 .unionRight {
  position: absolute;
  top: 19rem;
  left: 60.2rem;
}

.cont4 .icon {
  height: 3.5rem;
  width: 3.5rem;
  padding: .8rem;
}

.icon-b {
  box-sizing: border-box;
  border: .1rem solid #404D69;
  border-radius: 3.125rem;
  background-color: #FFFFFF06;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.cont4 .phone-call {
  position: absolute;
  top: 18.5rem;
  left: 9.9rem;
}

.cont4 .text-phone-call {
  position: absolute;
  top: 18.5rem;
  left: 14.9rem;
  width: 20%;
}

.cont4 .tick {
  position: absolute;
  top: 28rem;
  left: 4.5rem;
}

.cont4 .text-tick {
  position: absolute;
  top: 28rem;
  left: 9.5rem;
  width: 20%;
}

.cont4 .cloud1 {
  position: absolute;
  top: 28rem;
  left: 40rem;
}

.cont4 .process {
  position: absolute;
  top: 28rem;
  left: 48rem;
}

.cont4 .text-process {
  position: absolute;
  top: 28rem;
  left: 53rem;
  width: 20%;
}

.cont4 .bill {
  position: absolute;
  top: 38rem;
  left: 6rem;
}

.cont4 .text-bill {
  position: absolute;
  top: 38rem;
  left: 11rem;
  width: 20%;
}

.cont4 .construction {
  position: absolute;
  top: 38rem;
  left: 36rem;
}

.cont4 .text-construction {
  position: absolute;
  top: 38rem;
  left: 41rem;
  width: 20%;
}

.cont4 .telescope {
  position: absolute;
  top: 44rem;
  left: 20rem;
}

.cont4 .text-telescope {
  position: absolute;
  top: 45rem;
  left: 25rem;
  width: 20%;
}

.OurServices-Box {
  width: 100%;
  margin-bottom: 5rem;
}

.OurServices-Box .m-l {
  margin-right: 7.5%;
}
.OurServices-fl-box .h-2 {
  padding-top: .8rem;
  line-height: 2.5rem;
}

.OurServices-fl-box {
  width: 20%;
  margin-left: 6rem;
}

.OurServices-fr-box {
  width: 60%;
  margin-left: auto;
  margin-right: 5rem;
}

.Tech-box {
  width: 100%;
  margin-bottom: -5px;
}

.Tech-box img {
  padding: 1.5rem 2.2rem;
  height: 3rem;
  width: 3rem;
}

.border-right {
  border-right: .1rem solid #dee2e629;
}

.border-top {
  border-top: .1rem solid #dee2e629;
}

@media (max-width:1280px) {
  .cont2 .Tech-box{
    display: inline;
  }

  .cont2 .img {
    height: 5rem !important;
    width: 9rem !important;
    margin: 0rem 1rem !important;
  }
  
  .cont2 .logo-jupiter {
    margin-left: -1.5rem !important;
  }
  .cont4 .v-img {
    margin-left: 2rem;
  }
  
  .cont4 .map {
    left: 11rem;
  }
  .cont4 .text-map {
    left: 9rem;
  }
  
  .cont4 .cloud {
    left: 1rem;
  }
  
  .cont4 .logo {
    left: 55.7rem;
  }
  
  .cont4 .text-logo {
    left: 60rem;
  }
  
  .cont4 .unionLeft {
    top: 17.5rem;
    left: 53.3rem;
  }
  
  .cont4 .unionRight {
    top: 19rem;
    left: 56.3rem;
  }
  
  .cont4 .phone-call {
    top: 18.5rem;
    left: 5.6rem;
  }
  
  .cont4 .text-phone-call {
    left: 10.5rem;
  }
  
  .cont4 .tick {
    left: 1rem;
  }
  
  .cont4 .text-tick {
    left: 6rem;
  }
  
  .cont4 .cloud1 {
    left: 35rem;
  }
  
  .cont4 .process {
    left: 44rem;
  }
  
  .cont4 .text-process {
    left: 49rem;
  }
  
  .cont4 .bill {
    left: 1.5rem;
  }
  
  .cont4 .text-bill {
    left: 6.5rem;
  }
  
  .cont4 .construction {
    left: 32rem;
  }
  
  .cont4 .text-construction {
    left: 37rem;
  }
  
  .cont4 .telescope {
    top: 43.5rem;
    left: 18rem;
  }
  
  .cont4 .text-telescope {
    left: 23rem;
  }
  .OurServices-fl-box {
    width: 20%;
  }
  .OurServices-fl-box .h-2 {
    padding-top: .8rem;
    line-height: 2.5rem;
  }
  .OurServices-fr-box {
    width: 70%;
    margin-left: auto;
  }
  .Tech-box img {
    height: 2.7rem;
    width: 2.7rem;
  }
}
@media (max-width:1024px) {
  .box1 {
    width: calc(100% - 0%);
  }
  
  .box2 {
    width: calc(100% - 19%);
  }
  .cont2 .Tech-box{
    display: inline;
  }

  .cont2 .img {
    height: 5rem !important;
    width: 9rem !important;
    margin: 0rem 1rem !important;
  }
  
  .cont2 .logo-jupiter {
    margin-left: -1.5rem !important;
  }

  .cont4-box1 {
    width: 40%;
  }
  .cont4 .v-img {
    width: 75%;
    margin-left: 2rem;
    margin-top: 10rem;
  }
  
  .cont4 .map {
    top: 6.2rem;
    left: 8.8rem;
  }
  .cont4 .text-map {
    top: 5rem;
    left: 7rem;
  }
  
  .cont4 .cloud {
    top: 10rem;
    left: -3rem;
  }
  
  .cont4 .logo {
    top: 9.5rem;
    left: 44.7rem;
  }
  
  .cont4 .text-logo {
    top: 11rem;
    left: 48.5rem;
  }
  
  .cont4 .unionLeft {
    top: 12.8rem;
    left: 42.5rem;
  }
  
  .cont4 .unionRight {
    top: 14.1rem;
    left: 45.4rem;
  }
  
  .cont4 .phone-call {
    top: 12rem;
    left: 6rem;
  }
  
  .cont4 .text-phone-call {
    top: 12rem;
    left: 10rem;
  }
  
  .cont4 .tick {
    top: 21rem;
  }
  
  .cont4 .text-tick {
    top: 21rem;
    left: 5rem;
  }
  
  .cont4 .cloud1 {
    top: 21rem;
    left: 25rem;
  }
  
  .cont4 .process {
    top: 21rem;
    left: 35.5rem;
  }
  
  .cont4 .text-process {
    top: 21rem;
    left: 39.5rem;
  }
  
  .cont4 .bill {
    top: 29rem;
    left: 1.5rem;
  }
  
  .cont4 .text-bill {
    top: 29rem;
    left: 5.5rem;
  }
  
  .cont4 .construction {
    top: 29rem;
    left: 26rem;
  }
  
  .cont4 .text-construction {
    top: 29rem;
    left: 30rem;
  }
  
  .cont4 .telescope {
    top: 33.5rem;
    left: 16rem;
  }
  
  .cont4 .text-telescope {
    top: 34.5rem;
    left: 20rem;
  }
  .Tech-box img {
    height: 2.7rem;
    width: 2.7rem;
  }
  .cont4 {
    height: 60rem;
  }
  .cont4 .icon {
    height: 3rem;
    width: 3rem;
    padding: .8rem;
  }
  .OurServices-fl-box {
    width: 15%;
  }
  .OurServices-fl-box .h-2 {
    padding-top: .8rem;
    line-height: 2.5rem;
  }
  .OurServices-fr-box {
    width: 95%;
    margin-left: auto;
  }
  .Tech-box img {
    padding: 1.4rem;
  }
}

@media (min-width:550px) and (max-width:896px) {
  .box1 {
    width: 100%;
    padding-top: 9rem;
  }

  .btn-start {
    height: 3.5rem;
    width: 15.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  
  .box2 {
    width: 100%;
    padding-top: 2rem;
  }
  .cont2 {
    text-align: center;
    padding-top: 1rem;
    min-height: 20rem;
    background-color: #040812;
    margin-bottom: 10rem;
  }

  .cont2 .Tech-box{
    display: inline;
  }

  .cont2 .img {
    height: 4rem !important;
    width: 6rem !important;
    margin: 0rem 0rem -3rem 0rem !important;
  }
  
  .cont2 .logo-jupiter {
    margin-left: -1.5rem !important;
  }

  .img-border {
    height: 3rem;
    width: 3rem;
    padding: .5rem;
  }

  .cont3 {
    height: 15rem;
  }
  .cont3 .fl{
    margin: 0;
  }
  .cont3 .fr{
    width: 60%;
    margin: 0;
  }

  .cont4 {
    height: 80rem;
  }

  .cont4-box1 img{
    margin-bottom: 1rem;
   }

  .cont4-box1 {
    margin-bottom: 1rem;
    width: 50%;
    text-align: center;
  }
  
  .cont4-box2 {
    margin: auto;
    width: 60%;
    text-align: center;
  }
  .cont4 .v-img {
    display: none;
  }

  .cont4 .v-img2 {
    display: block;
    position: relative;
    margin-top: 7rem;
    margin-left: 10rem;
    height: 51rem;
  }
  .cont4 .map {
    top: -4rem;
    left: 7.9rem;
  }
  
  .cont4 .text-map {
    top: -5.5rem;
    left: 6rem;
  }
  
  .cont4 .cloud {
    display: none;
  }
  
  .cont4 .unionLeft {
    display: none;
  }
  
  .cont4 .unionRight {
    display: none;
  }
    
  .cont4 .cloud1 {
    display: none;
  }
  
  .cont4 .icon {
    height: 3rem;
    width: 3rem;
    padding: .5rem;
  }
  
  .cont4 .phone-call {
    top: 2rem;
    left: 8.5rem;
  }
  
  .cont4 .text-phone-call {
    top: 2rem;
    left: 13rem;
    width: 40%;
  }
  
  .cont4 .tick {
    top: 10rem;
    left: 8.5rem;
  }
  
  .cont4 .text-tick {
    top: 10rem;
    left: 13rem;
    width: 40%;
  }
  
  .cont4 .process {
    top: 18rem;
    left: 8.5rem;
  }
  
  .cont4 .text-process {
    top: 18rem;
    left: 13rem;
    width: 40%;
  }
  
  .cont4 .bill {
    top: 26rem;
    left: 8.5rem;
  }
  
  .cont4 .text-bill {
    top: 26rem;
    left: 13rem;
    width: 40%;
  }
  
  .cont4 .construction {
    top: 34rem;
    left: 8.5rem;
  }
  
  .cont4 .text-construction {
    top: 34rem;
    left: 13rem;
    width: 40%;
  }
  
  .cont4 .telescope {
    top: 42rem;
    left: 8.5rem;
  }
  
  .cont4 .text-telescope {
    top: 42rem;
    left: 13rem;
    width: 40%;
  }

  .cont4 .logo {
    top: 49rem;
    left: 8rem;
  }
  
  .cont4 .text-logo {
    top: 50rem;
    left: 13rem;
    width: 40%;
  }

  .OurServices-Box {
    width: 100%;
  }
  .OurServices-fl-box .h-2{
    line-height: 1.5rem;
  }
  .OurServices-Box .img{
    margin-left: -1rem;
  }
  .OurServices-Box .h-3{
    margin-top: .5rem;
    margin-left: -1rem;
  }
  
  .OurServices-fl-box {
    width: 20%;
    margin-left: 0rem;
  }

  .OurServices-fr-box {
    width: 80%;
    margin-left: 10rem;
  }
  
  .OurServices-box {
    width: 60%;
    margin-right: 3rem;
  }

  .OurServices-box2 {
    width: 40%;
    margin-left: -2rem;
  }
  .OurServices-fr-box .m-l{
    margin-right: 0rem;
  }
  .OurServices-fr-box .fc {
    margin-left: 2rem;
    width: 40%;
  }
  .OurServices-fr-box .fr {
    width: 40%;
  }

  .OurService-mt {
    margin-top: 2rem;
  }
  .OurService-mb {
    margin-top: -4rem;
  }
  
  .border-right {
    border-right: 0;
  }
  
  .border-top {
    border-top: 0;
  }
}

@media (max-width:550px) {
  .box1 {
    width: 100%;
    padding-top: 9rem;
  }

  .btn-start {
    height: 3.5rem;
    width: 15.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  
  .box2 {
    width: 100%;
    padding-top: 2rem;
  }
  .cont2 {
    text-align: center;
    padding-top: 1rem;
    min-height: 20rem;
    background-color: #040812;
    margin-bottom: 10rem;
    margin-left: .5rem;
    width: 94% !important;
  }

  .cont2 .Tech-box{
    display: inline;
  }

  .cont2 .img {
    height: 4rem !important;
    width: 6rem !important;
    margin: 0rem 0rem -3rem 0rem !important;
  }
  
  .cont2 .logo-jupiter {
    margin-left: -1.5rem !important;
  }

  .img-border {
    height: 3rem;
    width: 3rem;
    padding: .5rem;
  }

  .cont3 {
    height: 15rem;
  }
  .cont3 .h-2 {
    padding-top: 1rem;
  }
  .cont3 .fl{
    width: 100%;
    margin: 0;
    float: none;
  }
  .cont3 .fr{
    width: 100%;
    padding-top: 1rem;
    float: none;
  }

  .cont4 {
    height: 80rem;
  }

  .cont4-box1 img{
    margin-bottom: 1rem;
   }

  .cont4-box1 {
   margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  }
  
  .cont4-box2 {
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .cont4 .v-img {
    display: none;
  }

  .cont4 .v-img2 {
    display: block;
    position: relative;
    margin-top: 7rem;
    margin-left: 2rem;
    height: 51rem;
  }
  .cont4 .map {
    top: -4rem;
    left: -0.1rem;
  }
  
  .cont4 .text-map {
    top: -6rem;
    left: 7rem;
  }
  
  .cont4 .cloud {
    display: none;
  }
  
  .cont4 .unionLeft {
    display: none;
  }
  
  .cont4 .unionRight {
    display: none;
  }
    
  .cont4 .cloud1 {
    display: none;
  }
  
  .cont4 .icon {
    height: 3rem;
    width: 3rem;
    padding: .5rem;
  }
  
  .cont4 .phone-call {
    top: 2rem;
    left: 0.5rem;
  }
  
  .cont4 .text-phone-call {
    top: 2rem;
    left: 5rem;
    width: 60%;
  }
  
  .cont4 .tick {
    top: 10rem;
    left: 0.5rem;
  }
  
  .cont4 .text-tick {
    top: 10rem;
    left: 5rem;
    width: 60%;
  }
  
  .cont4 .process {
    top: 18rem;
    left: 0.5rem;
  }
  
  .cont4 .text-process {
    top: 18rem;
    left: 5rem;
    width: 60%;
  }
  
  .cont4 .bill {
    top: 26rem;
    left: 0.5rem;
  }
  
  .cont4 .text-bill {
    top: 26rem;
    left: 5rem;
    width: 60%;
  }
  
  .cont4 .construction {
    top: 34rem;
    left: 0.5rem;
  }
  
  .cont4 .text-construction {
    top: 34rem;
    left: 5rem;
    width: 60%;
  }
  
  .cont4 .telescope {
    top: 42rem;
    left: 0.5rem;
  }
  
  .cont4 .text-telescope {
    top: 42rem;
    left: 5rem;
    width: 60%;
  }

  .cont4 .logo {
    top: 49rem;
    left: 0rem;
  }
  
  .cont4 .text-logo {
    top: 50rem;
    left: 5rem;
    width: 60%;
  }

  .OurServices-Box {
    width: 100%;
  }
  .OurServices-fl-box{
    margin-left: 0rem;
  }
  .OurServices-fl-box .h-2{
    margin-top: 1rem;
    line-height: 1.5rem;
  }
  .OurServices-Box .img{
    margin-left: -1rem;
  }
  .OurServices-Box .h-3{
    margin-top: .5rem;
    margin-left: -1rem;
  }
  .OurServices-Box .fl {
    float: none;
  }
  .OurServices-Box .fr {
    float: none;
  }
  .OurServices-Box .fc {
    float: none;
  }
  
  .OurServices-box {
    width: 100%;
  }

  .OurServices-box2 {
    /* width: 85%; */
    margin-left: 2rem;
    margin-top: 2rem;
  }
  .OurService-mt {
    margin-top: 2rem;
  }
  .OurService-mb {
    margin-top: -4rem;
  }
  .OurServices-fr-box {
    /* width: 80%; */
    margin-left: -1rem;
  }
  
  .border-right {
    border-right: 0;
  }
  
  .border-top {
    border-top: 0;
  }
}

@media (max-width:320px) {
  .cont4{
    margin-top: 5rem;
  }
  .cont4 .text-map {
    top: -6rem;
    left: 4.5rem;
  }
  .cont5 {
    margin-top: 3rem;
  }
}