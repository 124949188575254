.Career {
  min-height: 190rem;
}

.Career-Box {
  margin: 6rem auto;
  width: 80%;
}

.Career-Box .fl {
  width: 40%;
}

.Career-Box .fr {
  width: 60%;
}

.Career .tile {
  margin: 2.4rem auto;
  box-sizing: border-box;
  height: 5rem;
  width: 80%;
  border: .1rem solid #283040;
  background-color: #FFFFFF06;
}

.Career .tile .fl {
  margin: 1.5rem;
  text-align: center;
  width: 20%;
}

.Career .tile .fc {
  margin: 1.5rem;
  text-align: center;
  width: 20%;
}

.Career .tile .fr {
  margin: 1.2rem;
  text-align: center;
  width: 20%;
}

.Career .tile .btn {
  box-sizing: border-box;
  height: 2.5rem;
  width: 5rem;
  border: .1rem solid #FFFFFF;
  border-radius: .6rem;
  background-color: rgba(20, 77, 224, 0);
}

.Career .tile .btn-text {
  color: #FFFFFF;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.Perks-Box {
  margin: 7rem auto;
  padding: 4rem;
  box-sizing: border-box;
  height: 33rem;
  width: 70%;
  border-radius: 1rem;
  border: .1rem solid #dee2e629;
  background-color: #FFFFFF10;
  box-shadow: 0 .4rem 1rem 0 rgba(0, 0, 0, 0.55);
}

.Perks-Box .fl {
  height: 10rem;
  width: 36%;
}

.Perks-Box .fr {
  height: 10rem;
  width: 30%;
}

.Perks-Box .h-p-2 {
  color: #FFFFFF;
  font-size: 2rem;
}

.Perks-Box .h-2 {
  font-size: 2rem;
  color: #1C62E9;
}

.Values-Box {
  margin: 5rem auto;
  width: 90%;
}

.Values-Box .h-2 {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: normal;
}

.Values-Box .fl {
  width: 40%;
}

.Values-Box .fr {
  width: 60%;
}

.form {
  position: relative;
  width: 100%;
}
.form .fl {
  width: 45% !important;
}
.form .fr {
  width: 45% !important;
}

.loading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 80vh;
}

.form input[type="file"]::-webkit-file-upload-button {
  background-color: #144DE0;
  color: #FFFFFF;
  height: 3rem;
  border: none;
}

.form input[type="file"] {
  margin: 1rem 0;
  box-sizing: border-box;
  height: 3rem;
  width: 100%;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;

  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.form .fl {
  width: 50%;
}

.form .fr {
  width: 50%;
}

.form .txt {
  padding: 1rem;
  margin: 1rem 0;
  box-sizing: border-box;
  height: 3rem;
  width: 100%;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;

  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.form .formLabel {
  color: #999999;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
  margin-top: 3.6rem;
}
.plane-icon{
  vertical-align: middle;
}

.form .btn-submit {
  margin-top: 2rem;
  height: 4rem;
  width: 16rem;
  border-radius: .6rem;
  background-color: #144DE0;
}

.form .btn-txt {
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
}

.form .captcha {
  margin-top: 1.5rem;
}

.Contact-Box {
  background-image: url('../Images/World\ Map.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  background-position-y: 3rem;
  height: 50rem;
  position: relative;
}

.Contact-Box .UK-Map {
  position: absolute;
  top: 9rem;
  left: calc(100% - 56%);
  width: 3.5rem;
}

.Contact-Box .IN-Map {
  position: absolute;
  top: 15rem;
  left: calc(100% - 41.5%);
  width: 3.5rem;
}

.txtbox {
  box-sizing: border-box;
  padding: .5rem 1rem;
  border-radius: .5rem;
  border: .2rem solid #20232C;
  background-color: #040812;
  align-items: center;
  color: #FF6C24;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.Contact-Box .txt-UK-Map {
  position: absolute;
  top: 9rem;
  left: calc(100% - 75%);
}

.Contact-Box .txt-IN-Map {
  position: absolute;
  top: 15rem;
  left: calc(100% - 37%);
}

.Contact {
  height: 50rem;
  margin-left: 10rem;
  position: relative;
}

.Contact-form {
  position: absolute;
  top: -22rem;
  left: calc(100% - 85%);
  padding: 4rem;
  box-sizing: border-box;
  height: 65rem;
  width: 70%;
  border-radius: .5rem;
  border: .1rem solid #283040;
  background-color: #131720;
}

.form .txtarea {
  padding: 1rem;
  margin: 1rem 0;
  box-sizing: border-box;
  height: 12rem;
  width: 100%;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;

  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.form .btn-select-txt {
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  padding: 1rem;
  text-align: center;
}

.form .btn-select-1 {
  box-sizing: border-box;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;
  margin-right: 1.5rem;
  cursor: pointer;
}

.form .btn-select-2 {
  box-sizing: border-box;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;
  margin-right: 1.5rem;
  cursor: pointer;
}

.form .btn-select-3 {
  box-sizing: border-box;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;
  margin-right: 1.5rem;
  cursor: pointer;
}

.form .btn-select-4 {
  box-sizing: border-box;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;
  margin-right: 1.5rem;
  cursor: pointer;
}

.form .btn-select-5 {
  box-sizing: border-box;
  border: .1rem solid #383C45;
  border-radius: .4rem;
  background-color: #131720;
  margin-right: 1.5rem;
  cursor: pointer;
}

.form .active_btn1 {
  background: linear-gradient(134.36deg, #0F792D 0%, #28B42E 100%);
}

.form .active_btn2 {
  background: linear-gradient(134.36deg, #0F792D 0%, #28B42E 100%);
}

.form .active_btn3 {
  background: linear-gradient(134.36deg, #0F792D 0%, #28B42E 100%);
}

.form .active_btn4 {
  background: linear-gradient(134.36deg, #0F792D 0%, #28B42E 100%);
}

.form .active_btn5 {
  background: linear-gradient(134.36deg, #0F792D 0%, #28B42E 100%);
}

.Team {
  height: 140rem;
}

.Member-Box {
  /* width: 80%; */
  width: 60%;
  margin: auto;
}

.Member-Box .CM-box {
  height: 25rem;
  /* width: 30%; */
  width: 40%;
  padding-top: 4rem;
  text-align: center;
  box-sizing: border-box;
  border-radius: 1rem;
  background-color: #FFFFFF10;
  box-shadow: 0 .4rem 1rem 0 rgba(0, 0, 0, 0.55);
  border-color: #dee2e629;
}

.Member-Box p {
  margin: 1rem 0rem;
}

.Member-Box .fc {
  margin-left: calc(100% - 95%);
}

@media (max-width:1920px) {
  .Contact-Box .UK-Map {
    top: 9rem;
    left: calc(100% - 55%);
  }

  .Contact-Box .IN-Map {
    top: 15rem;
    left: calc(100% - 43.3%);
  }

  .Contact-Box .txt-UK-Map {
    position: absolute;
    top: 9rem;
    left: calc(100% - 70%);
  }

  .Contact-Box .txt-IN-Map {
    position: absolute;
    top: 15rem;
    left: calc(100% - 40%);
  }
}

@media (max-width:1680px) {
  .Contact-Box .UK-Map {
    top: 8.5rem;
    left: calc(100% - 55.5%);
  }

  .Contact-Box .IN-Map {
    top: 15.2rem;
    left: calc(100% - 42.2%);
  }

  .Contact-Box .txt-UK-Map {
    top: 9rem;
    left: calc(100% - 73%);
  }

  .Contact-Box .txt-IN-Map {
    top: 15rem;
    left: calc(100% - 38%);
  }
}

@media (max-width:1440px) {
  .Contact-Box .UK-Map {
    top: 8.5rem;
    left: calc(100% - 56.5%);
  }

  .Contact-Box .IN-Map {
    top: 15.2rem;
    left: calc(100% - 41%);
  }

  .Contact-Box .txt-UK-Map {
    top: 9rem;
    left: calc(100% - 77%);
  }

  .Contact-Box .txt-IN-Map {
    top: 15rem;
    left: calc(100% - 36%);
  }

  .Perks-Box .fl {
    height: 10rem;
    width: 43%;
  }

  .Perks-Box .fr {
    height: 10rem;
    width: 35%;
  }
}

@media (max-width:1280px) {
  .Contact-Box .UK-Map {
    top: 9rem;
    left: calc(100% - 57%);
  }

  .Contact-Box .IN-Map {
    top: 15rem;
    left: calc(100% - 40%);
  }

  .Contact-Box .txt-UK-Map {
    position: absolute;
    top: 9rem;
    left: calc(100% - 79%);
  }

  .Contact-Box .txt-IN-Map {
    position: absolute;
    top: 15rem;
    left: calc(100% - 34.5%);
  }

  .py-5 {
    padding: 3rem 0 !important;
  }

  .form .btn-select-txt {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 1rem .6rem;
  }

  .form .btn-select-1 {
    margin: 1rem;
  }

  .form .btn-select-2 {
    margin: 1rem;
  }

  .form .btn-select-3 {
    margin: 1rem;
  }

  .form .btn-select-4 {
    margin: 1rem;
  }

  .form .btn-select-5 {
    margin: 1rem;
  }

  .form .txtarea {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .form .txt {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .Perks-Box .fl {
    height: 10rem;
    width: 43%;
  }

  .Perks-Box .fr {
    height: 10rem;
    width: 35%;
  }
}

@media (max-width:1024px) {
  .Contact-Box .UK-Map {
    top: 9rem;
    left: calc(100% - 59%);
  }

  .Contact-Box .IN-Map {
    top: 15rem;
    left: calc(100% - 37.5%);
  }

  .Contact-Box .txt-UK-Map {
    position: absolute;
    top: 9rem;
    left: calc(100% - 86%);
  }

  .Contact-Box .txt-IN-Map {
    position: absolute;
    top: 15rem;
    left: calc(100% - 31.5%);
  }

  .py-5 {
    padding: 3rem 0 !important;
  }

  .form .btn-select-txt {
    font-size: .8rem;
    line-height: 1.2rem;
    padding: 1rem .6rem;
  }

  .form .btn-select-1 {
    margin: .8rem;
  }

  .form .btn-select-2 {
    margin: .8rem;
  }

  .form .btn-select-3 {
    margin: .8rem;
  }

  .form .btn-select-4 {
    margin: .8rem;
  }

  .form .btn-select-5 {
    margin: .8rem;
  }

  .form .txtarea {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .form .txt {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .Perks-Box {
    width: 90%;
  }

  .Career .tile {
    width: 100%;
  }

  .Career-Box {
    width: 90%;
  }

  .Values-Box {
    width: 100%;
  }

  .Values-Box .h-2 {
    font-size: 1.5rem;
  }
}

@media (min-width:550px) and (max-width:896px) {
  .Career-Box {
    margin: 4rem auto;
    width: 100%;
  }
  
  .Career .tile {
    margin: 2.4rem auto;
    width: 100%;
  }
  
  .Career .tile .fl {
    margin: 1.5rem 0.5rem;
  }
  
  .Career .tile .fc {
    margin: 1.5rem 0.5rem;
  }
  
  .Career .tile .fr {
    margin: 1.2rem 0.5rem;
  }

  .Career .tile .btn {
    height: 2rem;
    width: 4rem;
  }
  
  .Career .tile .btn-text {
    font-size: .8rem;
    line-height: 1rem;
  }
  .modal-title {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }
  .form .fl {
    width: 100%;
  }
  .form .fr {
    width: 100%;
  }
  
  .form input[type="file"] {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .form .btn-submit {
    margin-top: 1rem;
    height: 3rem;
    width: 12rem;
  }
  
  .form .btn-txt {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .form .txt {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .Perks-Box {
    margin: 5rem auto;
    padding: 2rem;
    height: 26rem;
    width: 100%;
  }
  
  .Perks-Box .fl {
    height: 6rem;
    width: 50%;
    margin-bottom: 2rem;
  }
  
  .Perks-Box .fr {
    height: 6rem;
    width: 50%;
    margin-bottom: 2rem;
  }

  .Values-Box {
    margin: 5rem auto;
    width: 100%;
  }

  .Values-Box .p-5 {
    padding: 2rem;
  }
  
  .Values-Box .h-2 {
    font-size: 1.5rem;
  }
  
  .Values-Box .fl {
    width: 100%;
  }
  
  .Values-Box .fr {
    margin-top: 1rem;
    width: 100%;
  }
  .Team {
    height: 100rem;
  }
  
  .Member-Box {
    height: 22rem;
    /* width: 100%; */
    width: 80%;
    margin-bottom: -8rem;
  }

  .Member-Box img {
    margin-top: -1rem;
    height: 8rem;
  }
  
  .Member-Box .fl {
    height: 18rem;
    width: 32%;
  }
  .Member-Box .fc {
    margin-left: .9rem;
    height: 18rem;
    width: 32%;
  }
  .Member-Box .fr {
    height: 18rem;
    width: 32%;
  }
  
  .txtbox {
    font-size: .8rem;
    line-height: 1rem;
  }
  
  .Contact-Box .UK-Map {
    top: 9rem;
    left: calc(100% - 61.5%);
    width: 3rem;
  }

  .Contact-Box .IN-Map {
    top: 15.5rem;
    left: calc(100% - 32.5%);
    width: 3rem;
  }

  .Contact-Box .txt-UK-Map {
    top: 9.5rem;
    left: calc(100% - 93%);
  }

  .Contact-Box .txt-IN-Map {
    top: 16rem;
    left: calc(100% - 25%);
  }
  
  .Contact {
    height: 35rem;
    margin-left: 0rem;
  }
  
  .Contact-form {
    top: -28rem;
    left: 0;
    padding: 2rem .5rem;
    height: 61rem;
    width: 100%;
  }

  .form .h-2{
    line-height: 2.5rem;
  }
  
  .form .txtarea {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  
  .form .btn-select-txt {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: .5rem;
  }
  
  .form .btn-select-1 {
    padding: 1rem;
    margin: 1rem;
  }
  
  .form .btn-select-2 {
    padding: 1rem;
    margin: 1rem;
  }
  
  .form .btn-select-3 {
    padding: 1rem;
    margin: 1rem;
  }
  
  .form .btn-select-4 {
    padding: 1rem;
    margin: 1rem;
  }
  
  .form .btn-select-5 {
    padding: 1rem;
    margin: 1rem;
  }

}


@media (max-width:550px){
  .Career-Box {
    margin: 4rem auto;
    width: 100%;
  }

  .Career-Box .fl {
    float: none;
    width: 100%;
  }
  .Career-Box .fl .h-2 {
    line-height: 1.5rem;
  }
  .Career-Box .fr {
    float: none;
    margin-top: 1rem;
    width: 100%;
  }
  .Career .tile {
    margin: 2.4rem auto;
    width: 100%;
  }
  
  .Career .tile .fl {
    margin: 1.5rem 0.5rem;
  }
  
  .Career .tile .fc {
    margin: 1.5rem 0.5rem;
  }
  
  .Career .tile .fr {
    margin: 1.2rem 0.5rem;
  }

  .Career .tile .btn {
    height: 2rem;
    width: 4rem;
  }
  
  .Career .tile .btn-text {
    font-size: .8rem;
    line-height: 1rem;
  }
  .modal-title {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }
  
  .form .fl {
    float: none;
    width: 100% !important;
  }
  .form .fr {
    float: none;
    width: 100% !important;
  }
  .form input[type="file"] {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .form .btn-submit {
    margin-top: 1rem;
    height: 3rem;
    width: 12rem;
  }
  
  .form .btn-txt {
    font-size: .8rem;
    line-height: 1.2rem;
  }
  .form .txt {
    font-size: 1rem;
    line-height: 1.5rem;
    height: 3rem;
  }
  .Perks-Box {
    margin: 5rem auto;
    padding: 1rem;
    height: 32rem;
    width: 100%;
  }
  
  .Perks-Box .fl {
    height: 6rem;
    width: 100%;
  }
  
  .Perks-Box .fr {
    height: 6rem;
    width: 100%;
  }

  .Perks-Box .f-2{
    padding-top: 1rem;
  }

  .Values-Box {
    margin: 5rem auto;
    width: 100%;
  }

  .Values-Box .p-5 {
    padding: 2rem;
  }
  
  .Values-Box .h-2 {
    font-size: 1.5rem;
  }
  
  .Values-Box .fl {
    width: 100%;
  }
  
  .Values-Box .fr {
    margin-top: 1rem;
    width: 100%;
  }
  .Team {
    height: 170rem;
  }
  
  .Member-Box .CM-box {
    height: 22rem;
    width: 100%;
  }
  
  .Member-Box .fl {
    margin: 0 0 4rem 0;
  }
  .Member-Box .fc {
    margin: 0 0 4rem 0;
  }
  .Member-Box .fr {
    margin: 0 0 -4rem 0;
  }

  .Contact-Box .UK-Map {
    top: 9.3rem;
    left: calc(100% - 72%);
    width: 3rem;
  }
  
  .Contact-Box .IN-Map {
    top: 15.5rem;
    left: calc(100% - 17.5%);
    width: 3rem;
  }
  
  .txtbox {
    font-size: .5rem;
    line-height: .75rem;
  }
  
  .Contact-Box .txt-UK-Map {
    top: 9.7rem;
    left: calc(100% - 58%);
  }
  
  .Contact-Box .txt-IN-Map {
    top: 16rem;
    left: calc(100% - 42%);
  }
  
  .Contact {
    height: 35rem;
    margin-left: 0rem;
  }
  
  .Contact-form {
    top: -28rem;
    left: 0;
    padding: 2rem;
    height: 55rem;
    width: 100%;
  }

  .form .h-2{
    line-height: 1.5rem;
  }
  
  .form .txtarea {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .form .py-3 {
    padding: 2rem 0rem !important;
  }
  
  .form .btn-select-txt {
    font-size: .8rem;
    line-height: 1rem;
    padding: .5rem;
  }
  
  .form .btn-select-1 {
    margin: 4rem .2rem;
  }
  
  .form .btn-select-2 {
    margin: .2rem;
  }
  
  .form .btn-select-3 {
    display: block;
    width: 10rem;
    margin: 1rem .2rem -1rem .2rem;
  }
  
  .form .btn-select-4 {
    margin: .2rem;
  }
  
  .form .btn-select-5 {
    margin: .2rem;
  }

}

@media (max-width:320px) {
  .Career .tile .fl {
    margin: 1.5rem 0.2rem;
  }
  
  .Career .tile .fc {
    margin: 1.5rem 0.5rem;
  }
  
  .Career .tile .fr {
    margin: 1.2rem 0.5rem;
  }
  .Career .tile .btn {
    width: 3.5rem;
  }
  .Career .tile .btn-text {
    font-size: .8rem;
    line-height: 1rem;
  }
  .modal-title {
    font-size: 1rem;
    margin-bottom: .5rem;
  }

  .Contact-form {
    top: -28rem;
    left: 0;
    padding: 1.5rem;
    height: 59rem !important;
    width: 100%;
  }
  .form input[type="file"] {
    font-size: .8rem;
    line-height: 1rem;
    border: none;
  }

  .form .btn-submit {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 3rem;
    width: 12rem;
  }
  
  .form .btn-txt {
    font-size: .8rem;
    line-height: 1.2rem;
  }

  .Perks-Box .h-2{
    font-size: 1.7rem;
  }
  .Values-Box .h-2 {
    font-size: 1.1rem;
  }

  .Team {
    height: 180rem;
  }
  .form .btn-select-1 {
    margin: 4rem .2rem;
  }
  
  .form .btn-select-2 {
    display: block;
    width: 7rem;
    margin: 1rem .2rem -0.5rem .2rem;
  }
  
  .form .btn-select-3 {
    display: block;
    width: 10rem;
    margin: 1rem .2rem -1rem .2rem;
  }
  
  .form .btn-select-4 {
    display: block;
    width: 13rem;
    margin: -0.5rem .2rem -0.5rem .2rem;
  }
  
  .form .btn-select-5 {
    display: block;
    width: 4rem;
    margin: 1rem .2rem -0.5rem .2rem;
  }
}