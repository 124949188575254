@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");

@import url("Css/Navbar.css");
@import url("Css/Home.css");
@import url("Css/Footer.css");
@import url("Css/Services.css");
@import url("Css/Careers.css");

* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #040812;
    font-family: rubik;
}
.overflow {
    overflow-y: hidden;
}
.overflowActive {
    overflow-y: auto;
}
::-webkit-scrollbar {
    width: 0.1rem;
    background-color: #2a2d2e;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #060606;
    border-radius: 0.1rem;
}

button {
    border: none;
    cursor: pointer;
}

.container-fluid {
    min-height: 0vh;
    width: 100%;
    vertical-align: center;
}

.container {
    min-height: 0vh;
    width: calc(100% - 10%);
    margin-left: calc(100% - 95%);
    margin-right: calc(100% - 95%);
}

hr {
    border: 0.006rem solid #dee2e629;
}

.cont-frank {
    background-image: url('./Images/pexels-frank-cone.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cont-galaxy {
    background-image: url('./Images/galaxy.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.glob {
    background-image: url('./Images/glob.png');
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-position-y: 5rem;
    background-position-x: -15rem;
}

.row {
    width: 100%;
}
.text-center {
    text-align: center !important;
}
.text-666666 {
    color: #666666 !important;
}
.text-C5C5C5{
    color: #C5C5C5 !important;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.fc {
    float: left;
    margin-left: 10%;
}

.cls {
    clear: both;
}

.fl-box {
    margin-left: 10%;
    width: calc(100% - 60%);
}

.fr-box {
    margin-right: 10%;
    width: calc(100% - 60%);
}

.p-1-1 {
    padding-top: .3rem;
}

.p-1 {
    padding-top: 1rem;
}

.p-2 {
    padding-top: 2rem;
}
.p-5 {
    padding: 5rem;
}
.pb-5 {
    padding-bottom: 5rem;
}
.pr-1 {
    padding-right: 1rem;
}

.py-2 {
    padding: 2rem 0;
}
.p-img {
    padding-top: 0rem;
    padding-right: 0;
}

.py-5 {
    padding: 5rem 0;
}
.py-3 {
    padding: 3rem 0;
}
.mr-3 {
    margin-left: 3rem;
}

.my-1 {
    margin: .5rem 0rem;
}

.h-1 {
    font-size: 4.5rem;
    font-weight: 600;
    letter-spacing: 0;
    /* line-height: 6.75rem; */
    color: #FFFFFF;
}

.h-2 {
    color: #FFFFFF;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 3.75rem;
}

.h-3 {
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.875rem;
}

.h-4 {
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.5rem;
}

.f-1 {
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    color: #FFFFFF;
}

.f-2 {
    color: #AAAAAA;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.313rem;
}
.footer-banner-font-size {
    font-size: 3.6rem;
}
.Services-head-1{
    font-size: 64px;
}
.f-re {
    background: linear-gradient(52.12deg, #32FFE6 12.63%, #2260FF 81.05%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 0.938rem;
}

.f-re img {
    transform: translateX(.4rem);
    transition: all 0.3s ease;
}

.f-re:hover img {
    transform: translateX(1rem);
}
.modal {
    position: fixed;
    padding-top: .5rem;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.modal-sm {
    position: fixed;
    padding-top: 5rem;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.modal-header {
    border-bottom: .1rem solid;
    position: relative;
    padding-bottom: .5rem;
}
.modal-footer {
    border-top: .1rem solid;
    text-align: end;
}
.modal-body{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.modal .btn {
    margin-top: .5rem;
    height: 2rem;
    width: 4rem;
    color: #2a2d2e;
    background-color: #FFFFFF;
}
.close-btn {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    color: darkred;
    border: .1rem solid;
    padding: .1rem .3rem;
    border-radius: 50%;
    font-weight: 600;
  }
.modal-dialog {
    margin: auto;
    padding: 1rem 2rem;
    height: 94vh;
    overflow-y: auto;
    width: 70%;
    /* background-color: #2a2d2e; */
    background-color: #040812;
    color: #FFFFFF;
}
.modal-dialog-sm {
    margin: auto;
    padding: 2rem 2rem;
    height: 65vh;
    overflow-y: auto;
    width: 70%;
    /* background-color: #2a2d2e; */
    background-color: #040812;
    color: #FFFFFF;
}

@media (max-width:1440px) {
    .h-1 {
        font-size: 3.75rem;
        /* line-height: 5.625rem; */
    }
    .f-1 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (max-width:1280px) {
    .glob {
        background-position-x: -20rem;
    }
    .h-1 {
        font-size: 3.75rem;
        /* line-height: 5.625rem; */
    }
    .f-1 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media (max-width:1024px) {
    .glob {
        background-size: 80%;
        background-position-x: -16rem;
        background-position-y: 4rem;
    }
    .h-1 {
        font-size: 3.75rem;
        /* line-height: 5.625rem; */
    }
    .f-1 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .h-2 {
        font-size: 2.188rem;
        line-height: 3.75rem;
    }
    .h-3 {
        font-size: 1.125rem;
        line-height: 1.688rem;
    }
    .f-2 {
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}

@media (min-width:550px) and (max-width:896px)  {
    .cont-frank {
        background-image: url('./Images/pexels-frank-cone.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: -3rem;
    }
    .glob {
        background-image: url('./Images/glob.png');
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: 15rem;
        /* background-position-x: -20rem; */
    }
    .h-1 {
        font-size: 3rem;
        /* line-height: 4.5rem; */
    }
    .f-1 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .h-2 {
        font-size: 1.5rem;
        line-height: 2.2rem;
    }
}

@media (max-width:550px) {
    .cont-frank {
        background-image: url('./Images/pexels-frank-cone.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: -3rem;
    }
    .glob {
        background-image: url('./Images/glob.png');
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-position-y: 12rem;
        background-position-x: -20rem;
    }
    .h-1 {
        font-size: 2rem;
        /* line-height: 3rem; */
    }
    .f-1 {
        font-size: .8rem;
        line-height: 1.2rem;
    }
    .h-2 {
        font-size: 1.4rem;
        line-height: 1.1rem;
    }
}