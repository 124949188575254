.cont-Services {
    min-height: 20rem;
    text-align: center;
    background: radial-gradient(circle, rgba(25, 37, 104, 0.69) 0%, #040812 100%);
  }
.cont-Services .S-h1{
    margin: auto;
    padding-top: 8rem;
    width: 80%;
}
.cont-Services .S-h2{
    margin: auto;
    padding-top: 2rem;
    width: 75%;
    padding-bottom: 5rem;
}
  .cont-Services .btn {
    margin-top: 3rem;
    box-sizing: border-box;
    height: 5rem;
    width: 20rem;
    border: 1px solid #FFFFFF;
    border-radius: .6rem;
    background-color: rgba(20, 77, 224, 0);
  }
  .cont-Services .btn-text {
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;
  }

  .cont-Services .arrowDown {
    margin-left: 1rem;
  }

  .contS-PE {
    min-height: 90rem;
    margin-bottom: 5rem;
  }
  .card {
    margin: 1.6rem 0;
    padding: 3.3rem 4rem;
    box-sizing: border-box;
    height: 23rem;
    width: 48%;
    border: .1rem solid #283040;
    border-radius: 1rem;
    background-color: #FFFFFF06;
    box-shadow: 0 .4rem 1rem 0 rgba(0, 0, 0, 0.55);
  }

  .card .fl {
    width: 10%;
  }
  .card .fr {
    margin-right: 25%;
    margin-top: -5%;
    width: 60%;
  }
  .card .fr.s {
    margin-right: 15% !important;
    width: 70% !important;
  }

  .img-p {
    vertical-align: top;
    display: inline;
  }  

  .card-p {
    width: 50%;
  }

  .contS-DT {
    height: 35rem;
  }
  .contS-DT .fl {
    width: 30%;
  }
  .contS-DT .fc {
    margin-left: 5%;
    width: 30%;
  }
  .contS-DT .fr {
    width: 30%;
  }
  .contS-DT .chart {
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 1rem;
  }
  .contS-DT .chart-box {
    margin-left: 10%;
    width: 80%;
  }
  .contS-DT .h-3{
    width: 70%;
    margin-bottom: .5rem;
  }

  .contS-DC {
    height: 65rem;
  }

  .contS-DC .tile {
    margin: 2.4rem auto;
    box-sizing: border-box;
    height: 5rem;
    width: 80%;
    border: .1rem solid #283040;
    background-color: #FFFFFF06;
    /* box-shadow: 0 .4rem 1rem 0 rgba(0, 0, 0, 0.55); */
    border-left: .5rem solid #29A3F5;
  }

  .contS-DC .tile p {
    margin-left: 4rem;
    margin-top: 1.5rem;
  }

  .cont-RPA {
    height: 90rem;
    width: 90%;
  }
  
  .RPA-box {
    position: relative;
    height: 60rem;
    width: 70%;
    margin: auto;
  }

  .RPA-box .Num {
    color: #FFFFFF10;
    font-size: 10rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5rem;
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .RPA-box .Num-One {
    position: absolute;
    top: 5;
    left: 26rem;
  }

  .RPA-box .targetCircle {
    position: absolute;
    top: -4rem;
    left: 3rem;
  }
  
  .RPA-box .One-text {
    position: absolute;
    top: 0.3rem;
    left: 4rem;
    width: 12.5rem;
  }
  
  .RPA-box .Num-Two {
    position: absolute;
    top: 20rem;
    left: 55rem;
  }

  .RPA-box .photoFilter {
    position: absolute;
    top: -4rem;
    left: 3rem;
  }
  
  .RPA-box .Two-text {
    position: absolute;
    top: -1rem;
    left: 4rem;
    width: 13rem;
  }
  
  .RPA-box .Num-Three {
    position: absolute;
    top: 40rem;
    left: 55rem;
  }

  .RPA-box .codeFilled {
    position: absolute;
    top: -4rem;
    left: 3.5rem;
  }
  
  .RPA-box .Three-text {
    position: absolute;
    top: -1rem;
    left: 4rem;
    width: 13rem;
  }
  
  .RPA-box .Num-Four {
    position: absolute;
    top: 55rem;
    left: 26rem;
  }

  .RPA-box .fluentBot {
    position: absolute;
    top: -4.5rem;
    left: 4rem;
  }
  
  .RPA-box .Four-text {
    position: absolute;
    top: -1rem;
    left: 4.5rem;
    width: 13rem;
  }
  
  .RPA-box .Num-Five {
    position: absolute;
    top: 40rem;
    left: 0rem;
  }

  .RPA-box .testTube {
    position: absolute;
    bottom: 3rem;
    left: 4rem;
  }
  
  .RPA-box .Five-text {
    position: absolute;
    top: -1rem;
    left: 4rem;
    width: 13rem;
  }
  
  .RPA-box .Num-Six {
    position: absolute;
    top: 20rem;
    left: 3rem;
  }

  .RPA-box .businessEye {
    position: absolute;
    top: -4rem;
    left: 3.5rem;
  }
  
  .RPA-box .Six-text {
    position: absolute;
    top: 0rem;
    left: 4rem;
    width: 12rem;
  }
  
  .RPA-box .logo {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
  }
  
  .RPA-box .Logo {
    position: absolute;
    top: 25rem;
    left: 18rem;
    text-align: center;
  }
  
  .RPA-box .logo-text {
    width: 35rem;
    font-style: italic;
  }
  
  .Sv-line {
    display: none;
  }
  .v-dot1 {
    display: none;
  }
  .v-dot2 {
    display: none;
  }
  .v-dot3 {
    display: none;
  }
  .v-dot4 {
    display: none;
  }
  .v-dot5 {
    display: none;
  }
  .v-dot6 {
    display: none;
  }

  .RPA-box .arrow-1 {
    position: absolute;
    top: 4rem;
    left: 18rem;
  }
  
  .RPA-box .arrow-2 {
    position: absolute;
    top: 7rem;
    left: 2rem;
  }
  
  .RPA-box .arrow-3 {
    position: absolute;
    top: 6rem;
    right: 6rem;
  }
  
  .RPA-box .arrow-4 {
    position: absolute;
    bottom: 3rem;
    right: 12rem;
  }
  
  .RPA-box .arrow-5 {
    position: absolute;
    bottom: 8rem;
    left: 4rem;
  }
  
  .RPA-box .arrow-6 {
    position: absolute;
    transform: rotate(12deg);
    top: -13rem;
    left: 10rem;
  }

  @media (max-width:1440px) {
    .card {
        height: 25rem;
      }
    .card .fr.s {
        margin-right: 1% !important;
        width: 80% !important;
      }
      .cont-Services .S-h2{
        width: 82%;
    }
  }

  @media (max-width:1280px) {
    .card {
        height: 27rem;
      }
    .card .fr.s {
        margin-right: 1% !important;
        width: 85% !important;
      }
      .cont-Services .S-h2{
        width: 90%;
    }
    .RPA-box {
      width: 100%;
    }
  }
  @media (max-width:1024px) {
  .cont-RPA {
    width: 100%;
  }  
  .RPA-box {
    width: 100%;
  }
  
  .RPA-box .Num-One {
    top: 5;
    left: 20rem;
  }
  
  .RPA-box .One-text {
    top: 1rem;
    width: 11rem;
  }
  
  .RPA-box .Num-Two {
    left: 45.7rem;
  }
  
  .RPA-box .Two-text {
    top: -0.5rem;
    width: 11rem;
  }
  
  .RPA-box .Num-Three {
    left: 45.7rem;
  }
  
  .RPA-box .Three-text {
    top: -0.5rem;
    width: 11rem;
  }
  
  .RPA-box .Num-Four {
    left: 20rem;
  }
  
  .RPA-box .Four-text {
    top: -0.5rem;
    width: 11rem;
  }
  
  .RPA-box .Num-Five {
    top: 40rem;
    left: -2rem;
  }
  
  .RPA-box .Num-Six {
    top: 20rem;
    left: 0rem;
  }
  
  .RPA-box .Logo {
    left: 10rem;
  }
  
  .RPA-box .arrow-1 {
    top: 5rem;
    left: 16rem;
  }
  
  .RPA-box .arrow-2 {
    top: 7rem;
    left: 4rem;
  }
  
  .RPA-box .arrow-3 {
    top: 8rem;
    right: 5rem;
  }
  
  .RPA-box .arrow-4 {
    bottom: 2rem;
    right: 10rem;
  }
  
  .RPA-box .arrow-5 {
    left: 3rem;
  }
  
  .RPA-box .arrow-6 {
    top: -14rem;
    left: 8rem;
  }
  }
  @media (min-width:550px) and (max-width:896px)  {
    .cont-Services .S-h1{
      width: 100%;
  }
  .cont-Services .S-h2{
      width: 100%;
  }
  .card {
    padding: 1rem;
    height: 18rem;
    width: 100%;
  }
  .card .img {
    height: 3rem;
  }
  .card .fl {
    width: 20%;
  }
  .card .fr {
    margin-right: 15%;
    margin-top: 3%;
    width: 60%;
  }
  .card .fr.s {
    margin-right: 15% !important;
    width: 60% !important;
  }
  .contS-DT .fl {
    width: 100%;
    margin-bottom: 2rem;
    float: none;
  }
  .contS-DT .fc {
    margin-left: 0%;
    width: 100%;
    margin-bottom: 2rem;
    float: none;
  }
  .contS-DT .fr {
    width: 100%;
    margin-bottom: 2rem;
    float: none;
  }
  .contS-DT {
    height: 55rem;
    width: 100%;
  }
  .contS-DC .tile {
    height: 5rem;
    width: 100%;
  }
  
  .contS-DC .tile p {
    /* margin: 1.8rem 1.5rem; */
    text-align: center;
    margin: 1.8rem auto;
  }
  .cont-Services .btn {
    height: 3.5rem;
    width: 15rem;
  }
  
  .cont-RPA {
    height: 85rem;
  }  
  .RPA-box {
    height: 80rem;
    width: 50%;
  }
  
  .cont-RPA .text-center .h-2 {
    line-height: 2rem;
  }
  
  /* .Sv-line {
    display: block;
    position: relative;
    margin-top: 2rem;
    margin-left: 1.5rem;
    height: 55rem;
  }
  
  .v-dot1 {
    display: block;
    position: absolute;
    top: 4rem;
    left: 1.1rem;
  }
  .v-dot2 {
    display: block;
    position: absolute;
    top: 15rem;
    left: 1.1rem;
  }
  .v-dot3 {
    display: block;
    position: absolute;
    top: 26rem;
    left: 1.1rem;
  }
  .v-dot4 {
    display: block;
    position: absolute;
    top: 37rem;
    left: 1.1rem;
  }
  .v-dot5 {
    display: block;
    position: absolute;
    top: 48rem;
    left: 1.1rem;
  }
  .v-dot6 {
    display: block;
    position: absolute;
    top: 59rem;
    left: 1.1rem;
  } */
  
  /* .RPA-box .Num-One {
    position: absolute;
    top: 4rem;
    left: 5rem;
  }
  
  .RPA-box .Num-Two {
    position: absolute;
    top: 15rem;
    left: 5rem;
  }
  
  .RPA-box .Num-Three {
    position: absolute;
    top: 26rem;
    left: 5rem;
  }
  
  .RPA-box .Num-Four {
    position: absolute;
    top: 37rem;
    left: 5rem;
  }
  
  .RPA-box .Num-Five {
    position: absolute;
    top: 48rem;
    left: 5rem;
  }
  
  .RPA-box .Num-Six {
    position: absolute;
    top: 59rem;
    left: 5rem;
  }
  
  .RPA-box .Logo {
    display: none;
  }
  
  .RPA-box .arrow-1 {
    display: none;
  }
  
  .RPA-box .arrow-2 {
    display: none;
  }
  
  .RPA-box .arrow-3 {
    display: none;
  }
  
  .RPA-box .arrow-4 {
    display: none;
  }
  
  .RPA-box .arrow-5 {
    display: none;
  }
  
  .RPA-box .arrow-6 {
    display: none;
  } */
  }

@media (max-width:550px){
  .cont-Services .S-h1{
    width: 100%;
}
.cont-Services .S-h2{
    width: 100%;
}
.card {
  padding: 1rem;
  height: 18rem;
  width: 100%;
}
.card .h-2 {
  line-height: 1.6rem;
}
.card .img {
  height: 3rem;
}
.card .fl {
  width: 20%;
}
.card .fr {
  margin-right: 15%;
  margin-top: 3%;
  width: 60%;
}
.card .fr.s {
  margin-right: 5% !important;
  width: 70% !important;
}
.contS-DT .fl {
  width: 100%;
  margin-bottom: 2rem;
  float: none;
}
.contS-DT .fc {
  margin-left: 0%;
  width: 100%;
  margin-bottom: 2rem;
  float: none;
}
.contS-DT .fr {
  width: 100%;
  margin-bottom: 2rem;
  float: none;
}
.contS-DT {
  height: 55rem;
  width: 100%;
}
.contS-DC .tile {
  height: 5rem;
  width: 100%;
}

.contS-DC .tile p {
  padding-left: 1.5rem;
  margin: 1.8rem auto;
}
.cont-Services .btn {
  height: 3.5rem;
  width: 15rem;
}

.cont-RPA {
  height: 85rem;
}  
.RPA-box {
  height: 80rem;
}

.cont-RPA .text-center .h-2 {
  line-height: 2rem;
}

/* .Sv-line {
  display: block;
  position: relative;
  margin-top: 2rem;
  margin-left: 1.5rem;
  height: 55rem;
}

.v-dot1 {
  display: block;
  position: absolute;
  top: 4rem;
  left: 1.1rem;
}
.v-dot2 {
  display: block;
  position: absolute;
  top: 15rem;
  left: 1.1rem;
}
.v-dot3 {
  display: block;
  position: absolute;
  top: 26rem;
  left: 1.1rem;
}
.v-dot4 {
  display: block;
  position: absolute;
  top: 37rem;
  left: 1.1rem;
}
.v-dot5 {
  display: block;
  position: absolute;
  top: 48rem;
  left: 1.1rem;
}
.v-dot6 {
  display: block;
  position: absolute;
  top: 59rem;
  left: 1.1rem;
} */

.RPA-box .Num-One {
  /* position: absolute; */
  top: 4rem;
  left: 1.5rem;
}

.RPA-box .Num-Two {
  /* position: absolute; */
  top: 15rem;
  left: 1.5rem;
}

.RPA-box .Num-Three {
  /* position: absolute; */
  top: 26rem;
  left: 1.5rem;
}

.RPA-box .Num-Four {
  /* position: absolute; */
  top: 37rem;
  left: 1.5rem;
}

.RPA-box .Num-Five {
  /* position: absolute; */
  top: 48rem;
  left: 1.5rem;
}

.RPA-box .Num-Six {
  /* position: absolute; */
  top: 59rem;
  left: 1.5rem;
}

.RPA-box .Logo {
  display: none;
}

.RPA-box .arrow-1 {
  display: none;
}

.RPA-box .arrow-2 {
  display: none;
}

.RPA-box .arrow-3 {
  display: none;
}

.RPA-box .arrow-4 {
  display: none;
}

.RPA-box .arrow-5 {
  display: none;
}

.RPA-box .arrow-6 {
  display: none;
}
}


@media (max-width:320px){
  .card .fr.s {
    margin-right: 0% !important;
    width: 80% !important;
  }
}
   
  
  

  
 
  
  

  