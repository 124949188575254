.navbar_cont {
    background-color: transparent;
    position: fixed;
    z-index: 1;
}

.navbar_cont.active {
    color: #000;
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
}

.navbar {
    width: 100%;
    padding: .5rem;
    color: #FFFFFF;
    background-color: transparent;
}

.navbar-brand {
    vertical-align: middle;
}

.nav-list {
    width: calc(100% - 23%);
    text-align: center;
    list-style: none;
    display: inline-block;
}

.nav-item {
    display: inline;
    margin: 0rem 2rem;
}

.nav-link {
    text-decoration: none;
    color: #FFFFFF;
}

.nav-link:hover {
    color: #FFFFFF57;
}

.img-S {
    height: 1.2rem;
    margin-right: .5rem;
    vertical-align: middle;
}

.dropdown {
    display: inline;
    margin: 0rem .625rem;
}
.dropdown .downarrow {
    cursor: pointer;
    margin-left: 0.2rem;
    margin-bottom: 0.1rem;
    display: inline-block;
    width: .5rem;
    height: .5rem;
    border: .1rem solid #FFFFFF;
    border-top: 0rem;
    border-left: 0rem;
    transform: rotate(45deg);
}

.dropdown-list.active {
    display: inline;
}

.dropdown-list .shape {
    width: 1rem;
    height: 1rem;
    margin-top: -1.5rem;
    margin-left: 1rem;
    background-color: #2a2d2e;
    border-bottom: 0rem;
    border-right: 0rem;
    transform: rotate(45deg);
}

.dropdown-list {
    display: none;
    position: absolute;
    margin-left: -5rem;
    margin-top: 2rem;
    padding: 1rem;
    background-color: #2a2d2e;
    border-radius: 1rem;
    transition: 0.5s all;
}

.dropdown-item {
    line-height: 2rem;
    padding: .5rem 0;
    transition: 0.3s all;
    text-align: start;
}

.dropdown-item:hover {
    opacity: 0.7;
}

.flex {
    display: inline;
}

.btn-text {
    color: #FFFFFF;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.6rem;
    text-align: center;
}

.toggle-btn {
    display: none;
}
.sidebar {
    display: none;
}

.btn {
    height: 2.5rem;
    width: 9.375rem;
    border-radius: .3rem;
    background-color: #144DE0;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
}

@media (max-width:1280px) {
    .nav-list {
        width: calc(100% - 24%);
    }
}

@media (max-width:1024px) {
    .nav-list {
        width: calc(100% - 30%);
    }
}

@media (max-width:992px) {
    .nav-list {
        width: calc(100% - 40%);
    }
}

@media (max-width:768px) {
    .nav-item{
        display: none;
    }
    .dropdown {
        display: none;
    }
    .flex button {
        display: none;
    }
    .flex {
        display: inline; 
    }

    .toggle-btn {
        display: inline;
    }
    .toggle-btn {
        display: inline;
        margin-left: 70%;
        cursor: pointer;
    }
    .toggle-btn img{
        vertical-align: middle;
        height: 2rem;
    }

.sidebar {
    position: fixed;
    top: 3.5rem;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding: 2rem;
    color: #FFFFFF;
    background-color: #040812;
    display: none;
}

.sidebar.active {
    display: block;
}

.sidebar-brand {
    vertical-align: middle;
}

.sidebar-list {
    list-style: none;
}
.sidebar-item {
    padding-bottom: 1rem;
}

.sidebar-link {
    text-decoration: none;
    color: #FFFFFF;
}

.sidebar-link:hover {
    color: #FFFFFF57;
}
.Sdropdown {
    padding-bottom: 1rem;
}

.Sdropdown .downarrow {
    cursor: pointer;
    margin-left: 0.2rem;
    margin-bottom: 0.1rem;
    display: inline-block;
    width: .5rem;
    height: .5rem;
    border: .1rem solid #FFFFFF;
    border-top: 0rem;
    border-left: 0rem;
    transform: rotate(45deg);
}

.Sdropdown-list {
    display: block;
    list-style: none;
}

.Sdropdown-item {
    padding-bottom: 1rem;
}
}

@media (max-width:550px) {
    .nav-item{
        display: none;
    }
    .dropdown {
        display: none;
    }
    .flex button {
        display: none;
    }
    .flex {
        display: inline; 
    }

    .toggle-btn {
        display: inline;
        margin-left: 55%;
        cursor: pointer;
    }
    .toggle-btn img{
        vertical-align: middle;
        height: 2rem;
    }

.sidebar {
    position: fixed;
    top: 3.5rem;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding: 2rem;
    color: #FFFFFF;
    background-color: #040812;
    display: none;
}

.sidebar.active {
    display: block;
}

.sidebar-brand {
    vertical-align: middle;
}

.sidebar-list {
    list-style: none;
    font-size: 1.5rem;
}
.sidebar-item {
    padding-bottom: 1rem;
}

.sidebar-link {
    text-decoration: none;
    color: #FFFFFF;
}

.sidebar-link:hover {
    color: #FFFFFF57;
}
.Sdropdown {
    padding-bottom: 1rem;
}

/* .Sdropdown-list.active {
    display: block;
} */

.Sdropdown .downarrow {
    cursor: pointer;
    margin-left: 0.2rem;
    margin-bottom: 0.1rem;
    display: inline-block;
    width: .5rem;
    height: .5rem;
    border: .1rem solid #FFFFFF;
    border-top: 0rem;
    border-left: 0rem;
    transform: rotate(45deg);
}

.Sdropdown-list {
    display: block;
    font-size: 1.2rem;
    list-style: none;
}

.Sdropdown-item {
    padding-bottom: 1rem;
}
}
@media (max-width:320px){
    .toggle-btn {
        display: inline;
        margin-left: 45%;
        cursor: pointer;
    }
    .toggle-btn img{
        vertical-align: middle;
        height: 2rem;
    }
}